@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Euclid Circular A";
    font-weight: normal;
    src: "url('./assets/fonts/Euclid/EuclidCircularA-Regular.woff')"
      format("woff");
  }
  @font-face {
    font-family: "Euclid Circular A";
    font-weight: 600;
    src: "url('./assets/fonts/Euclid/EuclidCircularA-SemiBold.woff')"
      format("woff");
  }
  @font-face {
    font-family: "Euclid Circular A";
    font-weight: bold;
    src: "url('./assets/fonts/Euclid/EuclidCircularA-Bold.woff')" format("woff");
  }

  body {
    @apply font-sans;
  }
}
