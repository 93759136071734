.App {
  text-align: center;
  /* background-color: #a7aebd; */
  height: "100%";
}

.App-move {
  height: "100%";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #1e86a3;
}

.progress {
  width: 1000px;
  margin: 20px auto;
  text-align: center;
}
.progress .circle,
.progress .bar {
  display: inline-block;
  background: #fff;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  border: 1px solid #d5d5da;
}
.progress .bar {
  position: relative;
  width: 100px;
  height: 6px;
  top: -44px;
  /* margin-left: -5px;
  margin-right: -5px; */
  border-left: none;
  border-right: none;
  border-radius: 0;
}
.progress .circle .label {
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 32px;
  /* border: 1px solid #2626ac; */
  /* margin-top: 3px; */
  color: #b5b5ba;
  font-size: 18px;
}
.progress .circle .title {
  color: #0a0a0a;
  font-size: 13px;
  line-height: 30px;
  margin-left: -5px;
}

/* Done / Active */
.progress .bar.done,
.progress .circle.done {
  background: #eee;
}
.progress .bar.active {
  background: linear-gradient(to right, #eee 40%, #fff 60%);
}
.progress .circle.done .label {
  color: #fff;
  background: #8bc435;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
}
.progress .circle.done .title {
  color: #0a0a0a;
}
.progress .circle.active .label {
  color: #fff;
  background: #0c95be;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
}
.progress .circle.active .title {
  color: #0c95be;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
