.scroller {
  height: "500px";
  overflow-y: scroll;
  white-space: nowrap;
  display: flex;
  margin: 10rem;
}

label {
  font-size: 1.5rem;
}
