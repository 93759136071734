.test-enter {
  opacity: 1;
  height: auto;
  transform: scaleY(1);
}

.test-enter-active {
  opacity: 0.5;
  transform: scaleY(0.5);
  transition: all 300ms ease-out;
}

.test-enter-done {
  opacity: 0;
  height: "0px";
  transform: scaleY(0);
}

.test-exit {
  opacity: 0;
  height: "0px";
  transform: scaleY(0);
}

.test-exit-active {
  opacity: 0.5;
  transform: scaleY(0.5);
  transition: all 300ms ease-in;
}

.test-exit-done {
  opacity: 1;
  height: auto;
  transform: scaleY(1);
}
